<template>
  <div class="maintenance-contract-wrapper flex">
    <div class="search-content searchBox">
      <el-row>
        <el-col :span="20">
          <div class="search-item">
            <label>合同主体</label>
            <el-input type="text" v-model="filter.subjectName" placeholder="请输入劳动合同主体名称" size="medium" clearable></el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="button-group">
            <el-button type="primary" class="btn-search" @click="search()" size="medium">查询</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="table-content inline-flex contentBox">
      <div class="contentTool">
        <el-button @click="addContract" type="primary" size="medium">新增</el-button>
      </div>
      <el-table :data="tableData" class="contentTable" size="medium">
        <template v-for="col in cols">
          <el-table-column :label="col.label" width="col.width" v-if="col.prop==='operate'">
            <template  slot-scope="scope">
              <span class="mgr10 blue"  @click="editContract(scope.row)">编辑</span>
              <span class="mgr10 colorRed"  @click="deleteContract(scope.row.id)">删除</span>
            </template>
          </el-table-column>
          <el-table-column :prop="col.prop" :label="col.label" width="col.width" v-else></el-table-column>
        </template>
      </el-table>
      <reconova-pagination @paginationChange="paginationChange" :page-no="pageIndex" :page-size="pageSize" :total="total"></reconova-pagination>
    </div>
    <!-- 撤销弹窗  -->
    <el-dialog :title="isAdd?'新增':'编辑'" :visible.sync="showEditModal" width="560px">
      <el-form :model="editObj" ref="editContractForm" :rules="editContractRules" label-width="150px" class="dialog-form" size="medium">
        <el-form-item label="合同主体名称" prop="subjectName">
          <el-input class="" maxlength="50" placeholder="请输入" v-model="editObj.subjectName"></el-input>
        </el-form-item>
        <el-form-item label="别名" prop="anotherName">
          <el-input class="" maxlength="50" placeholder="请输入" v-model="editObj.anotherName"></el-input>
        </el-form-item>
        <el-form-item label="项目编号" prop="code">
          <el-input class="" maxlength="50" placeholder="请输入" v-model="editObj.code"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEditModal=false" size="medium">取消</el-button>
        <el-button type="primary" @click="submitContract" size="medium">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import reconovaPagination from "../../../../components/reconova/reconovaPagination";
export default {
  name: "maintenance-contract",
  components:{
    reconovaPagination
  },
  data(){
    const columns = [
      {prop:'subjectName',label:'合同主体'},
      {prop:'anotherName',label:'别名'},
      {prop:'code',label:'项目编号'},
      {prop:'updateTime',label:'最后更新日期'},
      {prop:'updateByStr',label:'更新人员'},
      {prop:'operate',label:'操作',width:120},
    ]
    return {
      ContractArr:[],
      tableData:[],
      cols:columns,
      isAdd:false,
      filter:{
        subjectName:''
      },
      pageSize:10,
      pageIndex:1,
      total:0,
      editObj:{},
      showEditModal:false,
      editContractRules:{
        subjectName:[this.$formValidator.empty('劳动合同主体')],
        code:[this.$formValidator.empty('编码')],
      }
    }
  },
  created() {
    this.search();
  },
  methods:{
    getParams(pageSize,pageIndex){
      return {
        ...this.filter,
        pageSize:pageSize,
        pageNum:pageIndex
      }
    },
    // 查询
    search(pageSize=10,pageIndex=1){
      let requestParam = this.getParams(pageSize,pageIndex);
      this.$api.personnel.getContracts(requestParam).then(response=>{
        this.tableData = response.data.list;
        this.total = response.data.total;
        this.pageIndex = response.data.pageNum;
        this.pageSize = response.data.pageSize;
      })
    },

    paginationChange(type,value){
      let size,index;
      if(type==='pageSize'){
        index = 1;
        size = value;
      }else{
        size = this.pageSize;
        index = value
      }
      this.search(size,index)
    },

    //  编辑
    editContract(data){
      this.editObj = JSON.parse(JSON.stringify(data))
      this.showEditModal = true;
      this.isAdd=false;
      this.$refs.editContractForm.clearValidate()
    },

    //  新增
    addContract(){
      this.editObj = {}
      this.showEditModal = true;
      this.isAdd=true;
      this.$refs.editContractForm.clearValidate()
    },

     // 删除
    deleteContract(id){
      this.$confirm('确定删除此数据？','提示',{
        type:'warning'
      }).then(()=>{
        this.$api.personnel.delContract(id).then(response=>{
          if(response){
            this.$message.success('删除成功');
            this.search()
          }
        })
      })
    },

    submitContract(){
      this.$refs.editContractForm.validate(validate=>{
        if(validate){
          let handler = this.isAdd?this.$api.personnel.addContract(this.editObj):this.$api.personnel.editContract(this.editObj)
          handler.then(res=>{
            this.showEditModal=false
            this.$message.success(this.isAdd?'添加成功':'修改成功');
            this.search()
          })
        }
      })
    }
  },


}
</script>

<style scoped lang="scss">
.el-table{
  flex: 1;
  overflow-y: auto;
}
.el-input,.el-select{
  width: 280px;
}
.flex{
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.inline-flex{
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.inline-block{
  display: inline-block;
}
</style>